<template>
    <CardToolbox>
        <SearchHeaderStyle>
            <sdPageHeader title="Transport Method">
                <template #subTitle>
                    <a-input v-model:value="searchValue" @change="onHandleSearch" placeholder="Search by Name">
                        <template #suffix>
                            <sdFeatherIcons type="search" transportMethod="16" />
                        </template>
                    </a-input>
                </template>
            </sdPageHeader>
        </SearchHeaderStyle>
    </CardToolbox>
    <Main>
        <a-row :gutter="15">
            <a-col class="w-100" :md="14">
                <sdCards title="List">
                    <div v-if="isLoading" class="spin">
                        <a-spin />
                    </div>

                    <div v-else>
                        <TableWrapper class="table-data-view table-responsive">
                            <a-table showPageSize @change="onHandleTableChange" :pagination="pagination"
                                :dataSource="dataSource" :columns="columns" />
                        </TableWrapper>
                    </div>
                </sdCards>
            </a-col>
            <a-col class="w-100" :md="10">
                <sdCards :title="HeaderInfo">
                    <FormValidationWrap>
                        <VerticalFormStyleWrap>
                            <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState"
                                :rules="rules" :layout="formState.layout">
                                <a-row :gutter="30">
                                    <a-col :md="24" :xs="24">
                                        <a-form-item ref="name" name="name" label="Name">
                                            <a-input v-model:value="formState.name" placeholder="Transport Method" />
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <div class="sDash_form-action">
                                    <sdButton v-if="localState.editionMode" v-on:click="switchUpdateOff()"
                                        class="sDash_form-action__btn" transportMethod="large" type="light">
                                        Cancel
                                    </sdButton>
                                    <sdButton v-if="localState.editionMode" type="primary" html-type="submit"
                                        class="sDash_form-action__btn" transportMethod="large">
                                        Edit
                                    </sdButton>
                                    <sdButton v-else type="primary" html-type="submit" class="sDash_form-action__btn"
                                        transportMethod="large">
                                        Add
                                    </sdButton>
                                </div>
                            </a-form>
                        </VerticalFormStyleWrap>
                    </FormValidationWrap>
                </sdCards>
            </a-col>
        </a-row>
    </Main>
</template>
<script>
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
    },
];

const TransportMethod = {
    name: "TransportMethod",
    components: {
        Main,
        TableWrapper,
        FormValidationWrap,
        VerticalFormStyleWrap,
        CardToolbox,
        SearchHeaderStyle,
    },
    setup() {
        const { state, dispatch } = useStore();
        const TransportMethodApi = computed(() => state.TransportMethodApi.data.data);
        const isLoading = computed(() => state.TransportMethodApi.loading);
        const searchValue = ref("");
        var loading = false;
        var transportMethodPagination = computed(() => state.TransportMethodApi.data);
        var sortDirections = "ascend";
        const HeaderInfo = computed(() => {
            if (localState.editionMode) {
                return "Edit transportMethod";
            }
            return "Add TransportMethod";
        });

        const localState = reactive({
            editionMode: false,
        });
        const formRef = ref();
        const formState = reactive({
            layout: "vertical",
            name: "",
            description: "",
            code: "",
        });

        const rules = {
            name: [
                {
                    required: true,
                    message: "This field is required",
                    trigger: "change",
                },
                {
                    min: 2,
                    max: 100,
                    message: "Field need to be longer then two and shorter then 100",
                    trigger: "change",
                },
            ],
        };

        onMounted(() => {
            dispatch("transportMethodGetPagedData", pagination.value);
            localState.editionMode = false;
        });
        const onHandleTableChange = (pagination) => {
            pagination = {
                ...pagination,
                sorter: {
                    order: sortDirections,
                },
                current: pagination.current,
                OrderBy: sortDirections,
                searchValue: searchValue.value,
            };

            dispatch("transportMethodGetPagedData", pagination);
        };
        var pagination = computed(() =>
            transportMethodPagination.value && transportMethodPagination.value.succeeded == true
                ? {
                    current: transportMethodPagination.value.pageNumber,
                    total: searchValue.value
                        ? transportMethodPagination.value.recordsFiltered
                        : transportMethodPagination.value.recordsTotal,
                    pageSize: transportMethodPagination.value.pageSize,
                    OrderBy: sortDirections,
                    searchValue: "",
                    sorter: {
                        order: sortDirections,
                    },
                }
                : {
                    current: 1,
                    pageSize: 10,
                    OrderBy: sortDirections,
                    searchValue: "",
                    sorter: {
                        order: sortDirections,
                    },
                }
        );
        const handleDelete = (id) => {
            const confirm = window.confirm("Are you sure delete this?");
            if (confirm) {
                dispatch("transportMethodApiDataDelete", { id });
            }
            return false;
        };

        const onHandleSearch = () => {
            sortDirections = "ascend";
            pagination = {
                ...pagination,
                current: 1,
                OrderBy: sortDirections,
                pageSize: 10,
                searchValue: searchValue.value,
            };

            dispatch("transportMethodGetPagedData", pagination);
        };
        const switchUpdateOn = (transportMethod) => {
            localState.editionMode = true;
            formState.name = transportMethod.name;
            formState.id = transportMethod.id;
        };
        const switchUpdateOff = () => {
            localState.editionMode = false;
            formState.name = "";
            formState.id = 0;
        };

        const dataSource = computed(() =>
            TransportMethodApi.value && TransportMethodApi.value.length
                ? TransportMethodApi.value.map((transportMethod, key) => {
                    const { id, name } = transportMethod;

                    return {
                        key: key + 1,
                        name,
                        action: (
                            <div class="table-actions">
                                <a-button class="edit" onClick={() => switchUpdateOn(transportMethod)}>
                                    <sdFeatherIcons type="edit" transportMethod={14} />
                                </a-button>
                                &nbsp;&nbsp;&nbsp;
                                <a-button class="delete" onClick={() => handleDelete(id)}>
                                    <sdFeatherIcons type="trash-2" transportMethod={14} />
                                </a-button>
                            </div>
                        ),
                    };
                })
                : []
        );
        onBeforeRouteLeave(() => {
            dispatch("emptyTransportMethod");
        });
        const onSubmit = () => {
            formRef.value
                .validate()
                .then(async () => {
                    if (!localState.editionMode) {
                        dispatch("transportMethodSubmitData", {
                            formState,
                        });
                    } else {
                        dispatch("transportMethodUpdateData", {
                            formState,
                        });
                    }
                    await nextTick();
                    switchUpdateOff();
                })
                .catch((error) => {
                    console.log("error", error);
                });
        };

        return {
            TransportMethodApi,
            formRef,
            rules,
            onSubmit,
            isLoading,
            localState,
            handleDelete,
            switchUpdateOn,
            switchUpdateOff,
            dataSource,
            HeaderInfo,
            columns,
            onHandleSearch,
            loading,
            sortDirections,
            formState,
            searchValue,
            transportMethodPagination,
            onHandleTableChange,
            pagination,
        };
    },
};

export default TransportMethod;
</script>
<style lang="scss">
.sDash_form-action {
    display: flex;
    justify-content: space-between;
}
</style>
